var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"69ab536f-40f7-4c73-8816-c1d87f5fe93d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { CaptureConsole, HttpClient } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  allowUrls: [
    'signup.megaphone.fm',
    'signup-uat.megaphone.fm',
    'megaphone-onboarding-web.spotify.com',
    'megaphone-onboarding-web-uat.spotify.com',
  ],
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [
    /* https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/plugin/#captureconsole */
    new CaptureConsole({
      levels: ['warn', 'error', 'debug'],
    }),
    /* https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/plugin/#httpclient */
    new HttpClient({
      failedRequestStatusCodes: [
        [500, 599],
        [401, 403],
      ],
    }),
    /*
      Group transactions for performance tracing
      https://docs.sentry.io/platforms/javascript/guides/react/performance/troubleshooting-performance/#group-transactions
    */
    new Sentry.BrowserTracing({
      beforeNavigate: context => {
        return {
          ...context,
          name: location.pathname.replace(
            /\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g,
            '/:id',
          ),
        };
      },
    }),
  ],
  release: process.env.NEXT_PUBLIC_SENTRY_VERSION || 'unknown',
  tracesSampleRate: 0.2,
});
